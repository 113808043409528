import { useEffect, useLayoutEffect } from "react";

const Terms = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "Cloudmind | Previous Terms of Use";
  });

  const pStyle = "text-base text-brand-dark lg:text-lg mb-4 lg:mb-8 w-full";

  return (
    <div id="#top" className="terms h-auto py-24 lg:py-48">
      <div className="font-open container mx-auto px-8 text-brand-dark lg:px-40 2xl:px-56">
        <h1 className="mb-24 mt-12 font-sans text-2xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-3xl">
          Terms and Conditions for Cloudmind
        </h1>

        <p className={`${pStyle}`}>
          Welcome to the Cloudmind website (the "Site"). By accessing or using
          the Site, you agree to be bound by the following terms and conditions
          (the "Terms"). If you do not agree to these Terms, you may not access
          or use the Site. Cloudmind reserves the right to change these Terms at
          any time, and your continued use of the Site following any changes
          will mean that you accept the new Terms.
        </p>

        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-2xl">
          1. Use of the Site
        </h2>

        <p className={`${pStyle}`}>
          You may use the Site only for lawful purposes and in accordance with
          these Terms. You agree not to use the Site:
        </p>

        <ul className="ml-4 list-disc lg:ml-8">
          <li>
            In any way that violates any applicable federal, state, local, or
            international law or regulation.
          </li>
          <li>
            For the purpose of exploiting, harming, or attempting to exploit or
            harm minors in any way by exposing them to inappropriate content,
            asking for personally identifiable information, or otherwise.
          </li>
          <li>
            To transmit, or procure the sending of, any advertising or
            promotional material, including any "junk mail," "chain letter,"
            "spam," or any other similar solicitation.
          </li>
          <li>
            To impersonate or attempt to impersonate Cloudmind, a Cloudmind
            employee, another user, or any other person or entity.
          </li>
          <li>
            To engage in any other conduct that restricts or inhibits anyone's
            use or enjoyment of the Site, or which, as determined by Cloudmind,
            may harm Cloudmind or users of the Site or expose them to liability.
          </li>
        </ul>

        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-2xl">
          2. User Content
        </h2>

        <p className={`${pStyle}`}>
          The Site may permit you to submit, upload, publish, or otherwise make
          available content, including but not limited to text, photographs,
          videos, and audio (collectively, "User Content"). You retain all
          rights in and are solely responsible for the User Content you make
          available through the Site. By making any User Content available
          through the Site, you grant to Cloudmind a non-exclusive,
          transferable, sub-licensable, royalty-free, worldwide license to use,
          copy, modify, create derivative works based on, distribute, publicly
          display, publicly perform, and otherwise exploit in any manner such
          User Content in all formats and distribution channels now known or
          hereafter devised (including in connection with the Site and
          Cloudmind's business and on third-party sites and services), without
          further notice to or consent from you, and without the requirement of
          payment to you or any other person or entity.
        </p>

        <p className={`${pStyle}`}>
          Cloudmind does not endorse any User Content or any opinion,
          recommendation, or advice expressed therein, and Cloudmind expressly
          disclaims any and all liability in connection with User Content.
          Cloudmind does not permit copyright infringing activities and
          infringement of intellectual property rights on its Site, and
          Cloudmind will remove all User Content if properly notified that such
          User Content infringes on another's intellectual property rights.
        </p>

        <p className={`${pStyle}`}>
          You represent and warrant that: (i) you either are the sole and
          exclusive owner of all User Content or you have all rights, licenses,
          consents, releases, and permits to use and authorize Cloudmind to use
          such User Content as permitted herein; and (ii) neither the User
          Content, nor your submission, uploading, publishing, or otherwise
          making available of such User Content, nor Cloudmind's use of the User
          Content as permitted herein will infringe, misappropriate, or violate
          a third party's patent, copyright, trademark, trade secret, moral
          rights, or proprietary or other proprietary rights, or rights of
          publicity or privacy, or result in the violation of any applicable law
          or regulation.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-2xl">
          3. Proprietary Rights
        </h2>
        <p className={`${pStyle}`}>
          The Site and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof), are owned by Cloudmind, its licensors, or other
          providers of such material and are protected by United States and
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws. These Terms permit
          you to use the Site for your personal, non-commercial use only. You
          hereby acknowledge that your use of the Site is at Cloudmind's sole
          discretion and that Cloudmind may terminate such use at any time. You
          agree not to engage in the use, copying, or distribution of any of the
          Site other than as expressly permitted herein, including any use,
          copying, or distribution of User Content of third parties obtained
          through the Site for any commercial purposes.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-2xl">
          4. Disclaimers
        </h2>
        <p className={`${pStyle}`}>
          THE SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. CLOUDMIND
          MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
          IMPLIED, AS TO THE OPERATION OF THE SITE OR THE INFORMATION, CONTENT,
          MATERIALS, OR PRODUCTS INCLUDED ON THE SITE. YOU EXPRESSLY AGREE THAT
          YOUR USE OF THE SITE IS AT YOUR SOLE RISK. TO THE FULL EXTENT
          PERMISSIBLE BY APPLICABLE LAW, CLOUDMIND DISCLAIMS ALL WARRANTIES,
          EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES
          OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. CLOUDMIND
          DOES NOT WARRANT THAT THE SITE, ITS SERVERS, OR E-MAIL SENT FROM
          CLOUDMIND ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. CLOUDMIND
          WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF
          THE SITE, INCLUDING, BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL,
          PUNITIVE, AND CONSEQUENTIAL DAMAGES.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-2xl">
          5. Limitation of Liability
        </h2>
        <p className={`${pStyle}`}>
          IN NO EVENT WILL CLOUDMIND, ITS AFFILIATES, LICENSORS, EMPLOYEES,
          AGENTS, SUCCESSORS, ASSIGNS, CONTENT OR SERVICE PROVIDERS, OR OTHER
          REPRESENTATIVES BE LIABLE FOR DAMAGES OF ANY KIND INCLUDING, WITHOUT
          LIMITATION, LOST PROFITS, LOST DATA, OR OTHER INTANGIBLE LOSSES, OR
          ANY INJURY, DEATH, LOSS, CLAIM, ACT OF GOD, ACCIDENT, DELAY, OR ANY
          SPECIAL, EXEMPLARY, PUNITIVE, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF
          ANY KIND, WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY, OR
          OTHERWISE, ARISING OUT OF YOUR USE OF THE SITE OR YOUR IN ABILITY TO
          USE THE SITE, OR FOR ANY OTHER CLAIM RELATED IN ANY WAY TO YOUR USE OF
          THE SITE, INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR OMISSIONS IN
          ANY CONTENT, OR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF
          THE USE OF ANY CONTENT (OR PRODUCT) POSTED, TRANSMITTED, OR OTHERWISE
          MADE AVAILABLE VIA THE SITE, EVEN IF ADVISED OF THEIR POSSIBILITY.
          BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE
          LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN
          SUCH STATES OR JURISDICTIONS, CLOUDMIND'S LIABILITY SHALL BE LIMITED
          TO THE MAXIMUM EXTENT PERMITTED BY LAW.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-2xl">
          6. Indemnification
        </h2>
        <p className={`${pStyle}`}>
          You agree to indemnify, defend, and hold harmless Cloudmind, its
          affiliates, licensors, employees, agents, successors, assigns,
          content, or service providers, or other representatives from and
          against any and all claims, damages, liabilities, costs, and expenses,
          including attorneys' fees, arising from your use of the Site,
          including but not limited to User Content submitted by you, or your
          violation of these Terms.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-2xl">
          7. General Terms
        </h2>
        <p className={`${pStyle}`}>
          These Terms and the Privacy Policy constitute the entire agreement
          between you and Cloudmind with respect to the use of the Site. If any
          provision of these Terms is held to be invalid or unenforceable, such
          provision shall be struck and the remaining provisions shall be
          enforced. Cloudmind's failure to enforce any right or provision in
          these Terms shall not constitute a waiver of such right or provision
          unless acknowledged and agreed to by us in writing. In the event of
          any conflict between these Terms and any contract you have with
          Cloudmind, the terms of your contract will control.
        </p>
        <p className={`${pStyle}`}>
          These Terms and your use of the Site are governed under Washington
          state law. We each agree to submit to the personal jurisdiction of a
          state court located in King County, Washington or the United States
          District Court for the Western District of Washington.
        </p>
        <p className={`${pStyle}`}>
          These Terms do not create any agency, partnership, joint venture, or
          employment relationship, and you may not make any representation on
          our behalf. You may not assign these Terms or transfer any rights to
          use the Site without Cloudmind's prior written consent, and any
          unauthorized assignment and transfer shall be null and void.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-2xl">
          8. Contact Information
        </h2>
        <p className={`${pStyle}`}>
          If you have any questions about these Terms, please contact Cloudmind
          at{" "}
          <a
            href="mailto:contact@cloudmind.me"
            rel="noreferrer"
            className="underline"
            target="_blank"
          >
            contact@cloudmind.me
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default Terms;
