import { Link } from "react-router-dom";
import { useEffect, useLayoutEffect } from "react";

const Terms = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "Cloudmind | Terms of Use";
  });

  const pStyle = "text-base text-brand-dark lg:text-lg mb-4 lg:mb-8 w-full";

  return (
    <div id="#top" className="terms h-auto py-24 lg:py-48">
      <div className="font-open container mx-auto px-8 text-brand-dark lg:px-40 2xl:px-56">
        <h1 className="mb-24 mt-12 font-sans text-2xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-3xl">
          CloudMind Terms of Use
        </h1>
        <h3 class="mb-4 mt-2 font-sans text-lg font-bold capitalize lg:text-xl">
          Last revised: June 12, 2024
        </h3>
        <Link
          className="mb-12 block text-brand-alt underline transition-colors duration-300 hover:text-brand-alt_dark"
          to="/previous-terms"
        >
          Previous User Terms of Use
        </Link>{" "}
        <p className={`${pStyle}`}>
          <span class="font-bold">CLOUDMIND SOFTWARE INC.</span> (“
          <span class="font-bold">CloudMind</span>”, “
          <span class="font-bold">we</span>”, “
          <span class="font-bold">our</span>” or “
          <span class="font-bold">us</span>”) offers both a proprietary solution
          that tracks, reports and collects wellness data submitted by patients
          that are used by a patient's healthcare providers as part of one's
          treatment and healthcare monitoring plan by way of its mobile
          applications, including the CloudMind Apple App and the CloudMind
          Google App (collectively, the “
          <span class="font-bold">CloudMind Platform</span>”). You might access
          the CloudMind Platform through the monitoring devices provided by
          CloudMind for use in conjunction with the CloudMind Platform (the “
          <span class="font-bold">Hardware</span>”) or by other means as
          provided by CloudMind from time to time. The CloudMind Platform and
          Hardware, and all services related to it and their use, are
          collectively the “Services”.
        </p>
        <p className={`${pStyle}`}>
          PLEASE READ THESE TERMS OF USE (“<span class="font-bold">TERMS</span>
          ”) CAREFULLY. THESE TERMS CONSTITUTE A LEGALLY BINDING AGREEMENT
          BETWEEN YOU AND CLOUDMIND. THESE TERMS GOVERN YOUR ACCESS TO AND USE
          OF THE SERVICES. BY SIGNING UP FOR AN ACCOUNT, BY USING THE CLOUDMIND
          PLATFORM THROUGH THE HARDWARE OR OTHERWISE USING THE SERVICES, YOU
          AGREE TO BE BOUND BY THESE TERMS (INCLUDING THE LINKED DOCUMENTS
          REFERRED TO IN THESE TERMS), AS REVISED FROM TIME TO TIME. IF YOU DO
          NOT ACCEPT THESE TERMS, YOU MUST NOT ACCESS OR USE THE SERVICES. IF
          YOU ARE DISSATISFIED WITH THESE TERMS OR ANY OTHER TERMS, CONDITIONS,
          RULES, POLICIES, GUIDELINES OR PRACTICES APPLICABLE TO THE SERVICES,
          YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESS TO AND USE OF
          THE CLOUDMIND PLATFORM THE HARDWARE AND ALL OTHER ASPECTS OF THE
          SERVICES. YOU REPRESENT THAT YOU ARE AT LEAST THE LEGAL AGE OF
          MAJORITY IN YOUR JURISDICTION.
        </p>
        <p className={`${pStyle}`}>
          These Terms are effective on the earlier of the date (a) you click to
          accept these Terms, or (b) you first sign up for an account, use the
          CloudMind Platform or the Hardware or otherwise use the Services. You
          acknowledge the CloudMind Privacy Statement (the “Privacy Statement”)
          located at{" "}
          <Link
            className="text-brand-alt underline transition-colors duration-300 hover:text-brand-alt_dark"
            to="/privacy"
          >
            https://cloudmind.me/privacy
          </Link>{" "}
          , as revised from time to time, and you consent and agree to
          CloudMind's collection, use and disclosure of personal information as
          described in the Privacy Statement.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          1. Your Account.
        </h2>
        <p className={`${pStyle}`}>
          To use the Services, you must be invited by CloudMind to register an
          account. You will receive a request via email to register for the use
          of the Services through your care provider, or will be invited to
          register by your care provider on CloudMind's hardware. To register,
          you will need to include your email address, password and such further
          information as prompted through the registration process, as further
          detailed in CloudMind's Privacy Statement. You are responsible for
          maintaining the confidentiality of your account credentials. CloudMind
          recommends that you use a strong password, that you change it
          frequently, and that you do not reuse passwords. You agree not to
          disclose your username or password to any third party. CloudMind may
          reject, or require that you change, your username or password. You
          represent and warrant to CloudMind that you have not misrepresented
          any information that you have provided to CloudMind in connection with
          your account. You are solely responsible for all activities that occur
          under your account, including those performed by your caregiver. You
          shall abide by all applicable local, provincial, national and foreign
          laws, treaties and regulations in connection with use of the Services,
          including those related to data privacy, international communications
          and the transmission of technical or personal information. If you
          become aware of any unauthorized use of your account, you must notify
          CloudMind immediately. It is your responsibility to update or change
          your account information, as appropriate.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          2. Your Privacy and Personal Information.
        </h2>
        <p className={`${pStyle}`}>
          For a summary of how CloudMind collects, uses and discloses personal
          information, please see{" "}
          <Link
            className="text-brand-alt underline transition-colors duration-300 hover:text-brand-alt_dark"
            to="/privacy"
            target="_blank"
          >
            CloudMind's Privacy Statement.
          </Link>{" "}
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          3. License.
        </h2>
        <p className={`${pStyle}`}>
          CloudMind grants you, either directly or by way of your caregiver, a
          personal, revocable, limited, non-exclusive, royalty-free,
          non-transferable license to use the CloudMind Platform to access and
          use the end user functionality of the Services, and the content that
          CloudMind makes available to you on the CloudMind Platform, including
          materials, text, audio, video, photographs, maps, illustrations,
          graphics, the Marks (as hereinafter defined) and other media
          (“Content”), in each case subject to and conditional on your continued
          compliance with the terms and conditions of these Terms. All Content
          available through the CloudMind Platform is owned by CloudMind and
          CloudMind's third-party providers. All Content is provided for
          informational purposes only, and you are solely responsible for
          verifying the accuracy, completeness, and applicability of all Content
          and for your use of any Content. These Terms permit you to use the
          Services for your personal use only, and not for any commercial
          purpose. For greater certainty, CloudMind, in its sole discretion, may
          terminate or suspend your license to use some or all the Services or
          Content at any time, for any reason or no reason, with or without
          notice to you, and without any liability to you or any other person.
          If CloudMind terminates or suspends your license to use the some or
          all the Services or Content, these Terms will nevertheless continue to
          apply in respect of your use of the Services and Content prior to such
          termination or suspension.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          4. Your Content.
        </h2>
        <h3 class="mb-4 font-sans text-lg font-bold capitalize lg:mt-2 lg:text-xl">
          4.1
        </h3>
        <p className={`${pStyle}`}>
          The CloudMind Platform enables you to provide and upload content that
          includes but not limited to wellness data, feedback, messages, text,
          images, graphics, geographic data, and other information or content
          (collectively, "Your Content"), to the CloudMind Platform for the
          purposes of making Your Content available to your caregiver, your care
          provider and its staff. By using the Services, you acknowledge, agree
          and expressly consent to CloudMind sharing Your Content with such
          parties. You acknowledge and agree that you are solely responsible for
          all Your Content submitted, provided or uploaded and the consequences
          for submitting, providing or uploading it.
        </p>
        <h3 class="mb-4 font-sans text-lg font-bold capitalize lg:mt-2 lg:text-xl">
          4.2
        </h3>
        <p className={`${pStyle}`}>
          CloudMind will use Your Content in connection with providing the
          Services to you and providing Your Content to your caregiver, your
          care provider and its staff to monitor your well-being. You agree that
          by providing any of Your Content in whatever form and through whatever
          means, you grant to CloudMind a perpetual, worldwide, irrevocable,
          non-exclusive, sublicensable, royalty-free license to use, reproduce,
          process, display, publish, distribute, and make available all or any
          portion of such Your Content in connection with providing the Services
          to you and CloudMind's services to your care providers, and to
          incorporate Your Content in any form into the Services. This license
          includes the right to host, index, cache or otherwise format Your
          Content.
        </p>
        <h3 class="mb-4 font-sans text-lg font-bold capitalize lg:mt-2 lg:text-xl">
          4.3
        </h3>
        <p className={`${pStyle}`}>
          You further grant CloudMind a non-exclusive, perpetual, irrevocable,
          royalty-free, worldwide licence to reproduce, distribute, modify,
          create derivative works of and otherwise use Your Content to create
          de-identified, anonymized or aggregated data sets that no longer
          contains any information that identifies you (“Anonymized Data”). As
          between CloudMind and you, all right, title, and interest in
          Anonymized Data, and all intellectual property rights therein, belong
          to and are retained solely by CloudMind. You agree that CloudMind may
          (i) make Anonymized Data publicly available in compliance with
          applicable laws, and (ii) use Anonymized Data to the extent and in the
          manner permitted under applicable law, including to develop, optimize
          or promote CloudMind's products or services, provided that such
          Anonymized Data does not identify you, your caregiver, your long term
          care provider, or include any personal identifiable information that
          can be re-identified back to you, including your wellness data.
        </p>
        <h3 class="mb-4 font-sans text-lg font-bold capitalize lg:mt-2 lg:text-xl">
          4.4
        </h3>
        <p className={`${pStyle}`}>
          You represent and warrant that you own Your Content or have the
          necessary licenses, rights, consents and permissions to grant the
          license set forth herein and that its provision to CloudMind and
          CloudMind's use of Your Content will not violate the copyrights,
          privacy rights, publicity rights, trademark rights, contract rights or
          any other intellectual property rights or other rights of any third
          party.
        </p>
        <h3 class="mb-4 font-sans text-lg font-bold capitalize lg:mt-2 lg:text-xl">
          4.5
        </h3>
        <p className={`${pStyle}`}>
          You agree that CloudMind is not responsible for any violations of any
          third-party intellectual property or privacy rights in any of Your
          Content. You agree to pay all royalties, fees and any other monies
          owing to any person by reason of the Your Content uploaded, displayed
          or otherwise provided by you to the CloudMind Platform. You will only
          include in Your Content the personal information of another individual
          if you have the express permission of that individual or if you are
          otherwise entitled to do so at law.
        </p>
        <h3 class="mb-4 font-sans text-lg font-bold capitalize lg:mt-2 lg:text-xl">
          4.6
        </h3>
        <p className={`${pStyle}`}>Your content shall not:</p>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (a)
          </h4>
          <p className={`${pStyle}`}>
            violate any applicable law including any laws regarding the export
            of data or software, patent, trademark, trade secret, copyright, or
            other intellectual property, legal rights (including the rights of
            publicity and privacy of others) or contain any material that could
            give rise to any civil or criminal liability under applicable laws
            or that otherwise may conflict with these Terms.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (b)
          </h4>
          <p className={`${pStyle}`}>
            in any manner violate any third party right or any agreement between
            you and a third party.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (c)
          </h4>
          <p className={`${pStyle}`}>
            include or contain any material that is exploitive, obscene,
            harmful, threatening, abusive, harassing, hateful, defamatory,
            sexually explicit or pornographic, violent, inflammatory, or
            discriminatory based on race, sex, religion, nationality,
            disability, sexual orientation, or age or other such legally
            prohibited ground or be otherwise objectionable, such determination
            to be made in CloudMind's sole discretion.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (d)
          </h4>
          <p className={`${pStyle}`}>
            involve, provide, or contribute any false, inaccurate, or misleading
            information.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (e)
          </h4>
          <p className={`${pStyle}`}>
            impersonate or attempt to impersonate CloudMind or its employee(s)
            and contractor(s), another user, or any other person or entity
            (including, without limitation, by using email addresses, or screen
            names associated with any of the foregoing or that are not yours).
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (f)
          </h4>
          <p className={`${pStyle}`}>
            transmit, or procure the sending of, any advertisements or
            promotions, sales, or encourage any other commercial activities,
            including, without limitation, any "spam", "junk mail", "chain
            letter", contests, sweepstakes and other sales promotions, barter,
            or advertising or any other similar solicitation.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (g)
          </h4>
          <p className={`${pStyle}`}>
            encourage any other conduct that restricts or inhibits anyone's use
            or enjoyment of the Services, or which, as determined by CloudMind,
            may harm CloudMind or users of the CloudMind Platform or other
            Services or expose them to liability.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (h)
          </h4>
          <p className={`${pStyle}`}>
            cause annoyance, inconvenience, or needless anxiety or be likely to
            upset, embarrass, or alarm any other person.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (i)
          </h4>
          <p className={`${pStyle}`}>
            promote any illegal activity, or advocate, promote, or assist any
            unlawful act.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (j)
          </h4>
          <p className={`${pStyle}`}>
            give the impression that they originate from or are endorsed by
            CloudMind or any other person or entity if this is not the case.
          </p>
        </div>
        <h3 class="mb-4 font-sans text-lg font-bold capitalize lg:mt-2 lg:text-xl">
          4.7
        </h3>
        <p className={`${pStyle}`}>
          CloudMind has the right, without notice to:
        </p>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (a)
          </h4>
          <p className={`${pStyle}`}>
            remove or refuse to post any of Your Content for any or no reason in
            CloudMind's sole discretion.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (b)
          </h4>
          <p className={`${pStyle}`}>
            at all times, take such actions with respect to any of Your Content
            CloudMind deems necessary or appropriate in CloudMind's sole
            discretion.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (c)
          </h4>
          <p className={`${pStyle}`}>
            take appropriate legal action, including, without limitation,
            referral to law enforcement or any other governmental authority with
            respect to Your Content or your use of any of the Services. Without
            limiting the foregoing, CloudMind will fully cooperate with any law
            enforcement authorities or court order requesting or directing
            CloudMind to disclose the identity or other information of anyone
            posting any materials on or through the CloudMind Platform or other
            Services.
          </p>
        </div>
        <h3 class="mb-4 font-sans text-lg font-bold capitalize lg:mt-2 lg:text-xl">
          4.8
        </h3>
        <p className={`${pStyle}`}>
          CloudMind has no obligation, nor any responsibility to any party to
          monitor the CloudMind Platform or other services provided by
          CloudMind, and do not and cannot undertake to review material that
          you, your healthcare providers or other users submit. CloudMind cannot
          ensure prompt removal of objectionable material after it has been
          posted and CloudMind has no liability for any action or inaction
          regarding transmissions, communications, or content provided by any
          user or third party, subject to applicable laws.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          5. Unacceptable Use.
        </h2>
        <p className={`${pStyle}`}>
          You will, and will not permit any third party to: (a) make the
          CloudMind Platform or the Services generally or the Content available
          to, or use the Services or the Content for the benefit of, anyone
          other than yourself, (b) sell, resell, license, sublicense,
          distribute, make available, rent or lease the Services or the Content,
          or include the Services or the Content in a service bureau or
          outsourcing offering, (c) use Services to store or transmit any virus,
          Trojan horse, worm, or other software, script or code, the effect of
          which is to permit unauthorized access to, or to alter, disable,
          encrypt, erase, or otherwise harm, any computer, systems, software or
          data (collectively, “<strong>Malicious Code</strong>”), (d) interfere
          with or disrupt the integrity or performance of the Services, (e)
          attempt to gain unauthorized access to the CloudMind Platform or
          Content or their related systems or networks, (f) access or use any
          CloudMind intellectual property except as permitted under these Terms,
          (g) alter, modify, reproduce, obscure, copy or make derivative works
          from all or any part of the CloudMind Platform or the Content or any
          part, feature, function or user interface of the CloudMind Platform,
          (include any copyright, trademark, or any other proprietary notices
          that are provided on or in connection with the Services or any
          Content), (h) frame or mirror any part of the CloudMind Platform or
          the Content, or otherwise incorporate any portion of the CloudMind
          Platform or the Content into any product or service, (i) access or use
          the Services, in whole or in part, in order to build a competitive
          product or service or to benchmark with a non-CloudMind product or
          service, (j) reverse engineer the CloudMind Platform, or any software
          used to provide them (to the extent such restriction is permitted by
          applicable laws), (k) access or use any part of the Services or
          Content that is (expressly or implicitly) not intended for use by you,
          (l) use any non-CloudMind automation code in relation to the Services
          or Content (including any “bot” or “spider”), (m) collect or harvest
          any information from the Services or Content in a bulk or systematic
          way, (n) probe, scan, or test the vulnerability of the Services or any
          network connected to them, or breach the security or authentication
          measures on them or on any network connected to them, (o) collect,
          harvest, reverse look-up, trace, or otherwise seek to obtain any
          information on any other user of or visitor to the CloudMind Platform,
          (p) take any action that imposes an unreasonable or disproportionately
          large load on the infrastructure of the Services or any systems or
          networks connected to them, or (q) forge headers, impersonate a
          person, or otherwise manipulate identifiers in order to disguise your
          identity or the origin of any message you send to CloudMind or any
          other person on or through the Services.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          6. Health Services.
        </h2>
        <h3 class="mb-4 font-sans text-lg font-bold capitalize lg:mt-2 lg:text-xl">
          6.1
        </h3>
        <p className={`${pStyle}`}>
          Use of the Services is not a replacement or takes place of
          appointments with your regular care provider or appointments with your
          care provider and its staff. CloudMind may, but is not obligated to,
          provide your care provider with a report of the wellness data and Your
          Content.
        </p>
        <h3 class="mb-4 font-sans text-lg font-bold capitalize lg:mt-2 lg:text-xl">
          6.2
        </h3>
        <p className={`${pStyle}`}>
          As a term of using and receiving the Services, you acknowledge and
          agree to the following:
        </p>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            <strong>(a)</strong>
          </h4>
          <p className={`${pStyle}`}>
            <strong>
              THE SERVICES CANNOT BE USED FOR EMERGENCY HEALTHCARE. IF YOU
              BELIEVE THAT YOU ARE DEALING WITH AN EMERGENCY, YOU WILL CALL 911
              IMMEDIATELY OR ATTEND YOUR NEAREST EMERGENCY ROOM.
            </strong>
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (b)
          </h4>
          <p className={`${pStyle}`}>
            The following symptoms are NOT eligible to be treated through the
            Services: among other symptoms or conditions more suitable for
            attention by your caregiver or care provider, any impairment in
            level of consciousness; stroke like symptoms such as impaired
            sensation or paralysis to one side of your body; or inability to
            speak or comprehend language.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (c)
          </h4>
          <p className={`${pStyle}`}>
            You will report any medication reactions, side effects or other
            adverse events to your care provider or specialist (as appropriate),
            or if you do not have one, to the nearest walk-in clinic or
            emergency room, or in an emergency, call 911.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (d)
          </h4>
          <p className={`${pStyle}`}>
            You will seek emergency help or follow-up care when recommended by
            your care provider.
          </p>
        </div>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          7. Hardware.
        </h2>
        <p className={`${pStyle}`}>
          If you have received hardware or a tablet from CloudMind for use with
          the Services, the following terms apply to you:
        </p>
        <h3 class="mb-4 font-sans text-lg font-bold lg:mt-2 lg:text-xl">
          (a) Scope.
        </h3>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (i)
          </h4>
          <p className={`${pStyle}`}>
            CloudMind hereby agrees to loan the Hardware to you solely for Use
            as part and for the duration of your use of the Services in relation
            to which CloudMind agrees to make available such Hardware to you.
            For this section, the term “Use” means access, maintain, store,
            operate and/or otherwise use the Hardware; and the terms 'Using',
            'Used' and other derivative words of the term 'Use' will have the
            same meaning.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (ii)
          </h4>
          <p className={`${pStyle}`}>
            The Hardware is made available solely for your personal Use and not
            for resale or any other commercial purpose, unless expressly stated
            otherwise by CloudMind. You represent and warrant that the Hardware
            will always be Used: (i) solely for its intended purposes directly
            in connection with your use of the Services; (ii) in accordance with
            these Terms and any supporting documentation provided by CloudMind
            or the Hardware provider; and (iv) solely by the individual to whom
            the Hardware is assigned to, its caregiver, its long term care
            provider and the long term care provider's staff.
          </p>
        </div>
        <h3 class="mb-4 font-sans text-lg font-bold lg:mt-2 lg:text-xl">
          (b) Management.
        </h3>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (i)
          </h4>
          <p className={`${pStyle}`}>
            All Hardware will be uniquely identified by CloudMind in writing, at
            the time of or prior to your receipt of such Hardware.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (ii)
          </h4>
          <p className={`${pStyle}`}>
            All Hardware is leased to you as part of your care provider's
            subscription to the products and services provided by CloudMind.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (iii)
          </h4>
          <p className={`${pStyle}`}>
            Subject to sections 7(d) and 7(e) of these Terms, CloudMind is
            further responsible for all physical maintenance of the Hardware for
            as long as the Hardware is in the Your Custody. For this section,
            the Hardware is deemed in the “Your Custody” if such Hardware is in
            the physical possession of you.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (iv)
          </h4>
          <p className={`${pStyle}`}>
            Support with regards to the Hardware will be provided by CloudMind,
            under the umbrella of the support for the Services. Where CloudMind
            provides support to you, CloudMind's responsibility in providing
            support will consist of CloudMind using commercially reasonable
            efforts to identify and resolve all in-scope issues as the result of
            which the Hardware and/or the Services do not perform in a manner
            consistent with these Terms. As part of support, CloudMind reserves
            the right to replace the affected Hardware, in whole or in part,
            with a comparable or a better model at any time if CloudMind
            determines that such action is necessary for the resolution of the
            issue at the core of your support request.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (v)
          </h4>
          <p className={`${pStyle}`}>
            The Hardware may require an active connection to the internet,
            without which it may not work property or be compatible with the
            Services. If you are having issues with using the Services due to
            internet connectivity issues, promptly inform CloudMind or your care
            provider.
          </p>
        </div>
        <h3 class="mb-4 font-sans text-lg font-bold lg:mt-2 lg:text-xl">
          (c) Upgrades.
        </h3>
        <p className={`${pStyle}`}>
          As technologies evolve, CloudMind reserves the right to replace or
          upgrade the Hardware at its discretion, from time to time, at no
          additional charge to you. Any such upgraded equipment will be deemed
          Hardware provided under these Terms.
        </p>
        <h3 class="mb-4 font-sans text-lg font-bold lg:mt-2 lg:text-xl">
          (d) Liability for the Hardware.
        </h3>
        <p className={`${pStyle}`}>
          You understand and agree that to the extent that the Hardware is in
          your Custody, as between you and CloudMind, you and you alone are
          fully liable for (i) any repairs required due to the failure by you to
          Use the Hardware in accordance with these Terms or documentation
          provided by CloudMind or the Hardware supplier; (ii) any loss, theft,
          damage or alteration to the Hardware, whether or not covered by
          insurance; and (iii) any bodily harm or death caused directly or
          indirectly by the Hardware.
        </p>
        <h3 class="mb-4 font-sans text-lg font-bold lg:mt-2 lg:text-xl">
          (e) Damage to the Hardware.
        </h3>
        <p className={`${pStyle}`}>
          In the event of loss, theft, alteration or any other unauthorized Use
          of or damage to the Hardware while in the Your Custody, whether in
          whole or in part, you shall without delay so notify CloudMind in
          writing and, at CloudMind's option, you shall: (i) pay CloudMind for
          the repair of the Hardware to place it in good condition and working
          order; or (ii) if CloudMind determines that such Hardware is beyond
          repair, pay to CloudMind the cost of the hardware in the amount set
          out in Appendix 1 to these Terms (the “
          <strong>Replacement Value</strong>”), within thirty (30) days of such
          notification. In any case, no such loss, theft, damage, misuse or
          alteration shall relieve your payment and other obligations under
          these Terms.
        </p>
        <h3 class="mb-4 font-sans text-lg font-bold lg:mt-2 lg:text-xl">
          (f) Ownership.
        </h3>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (i)
          </h4>
          <p className={`${pStyle}`}>
            Unless expressly agreed to otherwise in writing between CloudMind
            and you, all Hardware will always remain the property of CloudMind.
            You may not assign any right or interest to the Hardware or permit
            any lien or encumbrance to exist thereon other than liens and
            encumbrances placed thereon by CloudMind, as the case may be. In
            case of violation of the above, You will be liable for paying to
            CloudMind the Replacement Value for the affected Hardware.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (ii)
          </h4>
          <p className={`${pStyle}`}>
            You shall not alter, remove or hide any notices affixed to the
            Hardware identifying CloudMind as the owner of the Hardware nor
            shall you permit any other person to do so.
          </p>
        </div>
        <h3 class="mb-4 font-sans text-lg font-bold lg:mt-2 lg:text-xl">
          (g) Termination and Return.
        </h3>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (i)
          </h4>
          <p className={`${pStyle}`}>
            In the event your use of the Services is terminated or expires, you
            must: (i) immediately cease Using the Hardware; and (ii) without
            undue delay (and, in no event, no later than thirty (30) days from
            the date of termination) have returned, at your cost, all Hardware
            to your care provider. Your Custody of each Hardware will end upon
            “Return” of such Hardware to CloudMind. Hardware will be deemed
            “Returned” to CloudMind upon CloudMind's written acknowledgement of
            a satisfactory inspection of the Hardware against alteration, damage
            and excessive Use following its receipt by CloudMind in accordance
            with the above.
          </p>
        </div>
        <div class="lg:pl-8">
          <h4 class="mb-4 font-sans text-base font-bold lg:mt-2 lg:text-lg">
            (ii)
          </h4>
          <p className={`${pStyle}`}>
            n the case of failure to Return any Hardware in accordance with the
            above, or failure to return such Hardware in a commercially useable
            condition (as determined by CloudMind in its sole discretion)
            because of loss, theft, alteration or any other unauthorized Use of
            or damage to the Hardware while in the Your Custody, CloudMind may,
            at its sole discretion, require you to purchase such Hardware from
            CloudMind and pay to CloudMind the Replacement Value.
          </p>
        </div>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          8. Trademarks.
        </h2>
        <p className={`${pStyle}`}>
          Certain names, graphics, logos, icons, designs, words, titles and
          phrases on the CloudMind Platform, including “CloudMind” and
          “CloudMind Software Inc.” may constitute trademarks, trade-names,
          trade dress and/or associated products and services of CloudMind or
          its affiliates (the “Marks”), and are protected in Canada and
          internationally and their display on the CloudMind Platform does not
          convey or create any licence or other rights in the Marks. Any use of
          any of the Marks, in whole or in part without prior written
          authorization of CloudMind or such third party is strictly prohibited.
          Other trademarks, trade names, trade dress and associated products and
          services mentioned on the CloudMind Platform, or through the Content
          and Services, may be the trademarks of their respective owners. The
          display of these trademarks, trade names, trade dress and associated
          products and services on the CloudMind Platform does not convey or
          create any licence or other rights in these trademarks or trade names.
          Any unauthorized use of them is strictly prohibited.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          9. Apple App Store.
        </h2>
        <p className={`${pStyle}`}>
          The following applies to any CloudMind Platform application you obtain
          from the Apple App Store (an “<strong>Apple CloudMind App</strong>”):
          You acknowledge and agree that these Terms are solely between you and
          CloudMind, and not with Apple, Inc. (“<strong>Apple</strong>”) and
          CloudMind, not Apple, is solely responsible for the Apple CloudMind
          App and the content thereof. You may only use the Apple CloudMind App
          on Apple branded products that you own or control. You must comply
          with the App Store Terms of Service. In the event of any inconsistency
          between a term of these Terms and a term of the{" "}
          <Link
            className="text-brand-alt underline transition-colors duration-300 hover:text-brand-alt_dark"
            to="https://www.apple.com/legal/internet-services/itunes/"
            target="_blank"
          >
            App Store Terms of Service
          </Link>{" "}
          , the term of the App Store Terms of Service will prevail. You
          acknowledge that Apple has no obligation whatsoever to furnish any
          maintenance and support services with respect to the Apple CloudMind
          App. In the event of any failure of the Apple CloudMind App to conform
          to any applicable warranty, you may notify Apple, and Apple will
          refund the purchase price for the Apple CloudMind App (if any) to you.
          To the maximum extent permitted by applicable law, Apple will have no
          other warranty obligation whatsoever with respect to the Apple
          CloudMind App, and any other claims, losses, liabilities, damages,
          costs or expenses attributable to any failure to conform to any
          warranty will be solely governed by these Terms and any law applicable
          to CloudMind as the supplier of the Apple CloudMind App. You
          acknowledge that Apple is not responsible for addressing any claims of
          you or any third party relating to the Apple CloudMind App or your
          possession and/or use of the Apple CloudMind App, including, but not
          limited to (a) product liability claims, (b) any claim that the Apple
          CloudMind App fails to conform to any applicable legal or regulatory
          requirement, and (c) claims arising under consumer protection or
          similar legislation, and all such claims are governed solely by these
          Terms and any law applicable to CloudMind as supplier of the Apple
          CloudMind App. You acknowledge that, in the event of any third-party
          claim that the Apple CloudMind App or your possession and use of the
          Apple CloudMind App infringes that third party's intellectual property
          rights, CloudMind, not Apple, will be solely responsible for the
          investigation, defense, settlement and discharge of any such
          intellectual property infringement claim, to the extent required by
          these Terms. You represent and warrant that (a) you are not located in
          a country that is subject to a U.S. Government embargo, or that is on
          Title 15, Part 740 Supplement 1 Country Group E of the U.S. Code of
          Federal Regulations, and (b) you are not listed on any U.S. Government
          list of prohibited or restricted parties. If you have any questions,
          complaints or claims with respect to the Apple CloudMind App, you may
          direct them to CloudMind by email at contact@cloudmind.me. You agree
          to comply with all applicable third-party terms of agreement when
          using the Apple CloudMind App, including your wireless data service
          agreement. You and CloudMind acknowledge and agree that Apple, and
          Apple's subsidiaries, are third-party beneficiaries of these Terms,
          and that, upon your acceptance of the terms and conditions of these
          Terms, Apple will have the right (and you will be deemed to have
          accepted the right) to enforce these Terms against you as a
          third-party beneficiary thereof.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          10. Google Play Store.
        </h2>
        <p className={`${pStyle}`}>
          The following applies to any CloudMind Platform application you obtain
          through the Google Play Store (a “
          <strong>CloudMind Google App</strong>”): You acknowledge and agree
          that these Terms are solely between you and CloudMind, and not with
          Google, Inc. or any of its subsidiaries (collectively, “
          <strong>Google</strong>”). You must comply with Google's then-current
          Google Play Terms of Service. In the event of any inconsistency
          between a term of these Terms and a term of the{" "}
          <Link
            className="text-brand-alt underline transition-colors duration-300 hover:text-brand-alt_dark"
            to="https://play.google.com/about/play-terms/index.html"
            target="_blank"
          >
            Google Play Terms of Service
          </Link>{" "}
          , the term of the Google Play Terms of Service will prevail. Google is
          only a provider of the Google Play Store where you obtained the
          CloudMind Google App. CloudMind, and not Google, is solely responsible
          for the CloudMind Google App. Google has no obligation or liability to
          you with respect to the CloudMind Google App or these Terms. You
          acknowledge and agree that Google is a third-party beneficiary of
          these Terms.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          11. Mobile Device Data Charges.
        </h2>
        <p className={`${pStyle}`}>
          You are solely responsible for any wireless or mobile data charges and
          similar fees associated with your use of the Services through the
          Hardware or your mobile device.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          12. Reservation of CloudMind Rights.
        </h2>
        <p className={`${pStyle}`}>
          CloudMind and its licensors have and will retain all right, title and
          interest in and to the CloudMind Platform, the Hardware and the
          Content and the software and systems used to provide them (including,
          without limitation, all patent, copyright, Marks, trade secret and
          other intellectual property rights), and all copies, modifications and
          derivative works of any of them. You acknowledge that you are
          obtaining only a limited right to access and use the Services. No
          rights are granted to you under these Terms other than as expressly
          set forth in these Terms. Without limitation, you have no right to use
          any Marks owned or used by CloudMind.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          13. Open Source.
        </h2>
        <p className={`${pStyle}`}>
          The CloudMind Platform may contain or be provided together with free
          or open-source software. Notwithstanding the sections titled “License”
          and “Reservation of CloudMind Rights”, each item of free or
          open-source software is subject to its own applicable license terms,
          which can be found in the applicable documentation or the applicable
          help, notices, about or source files as required by the terms of the
          applicable open-source license. Copyrights to the free and open-source
          software are held by the respective copyright holders indicated
          therein.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          14. License to Use Your Submission and Feedback.
        </h2>
        <p className={`${pStyle}`}>
          You grant to CloudMind and its affiliates a worldwide, perpetual,
          irrevocable, royalty-free, transferable, sublicensable (through
          multiple tiers) license to use and incorporate into the Services. the
          Content, and any other CloudMind products and services any ideas,
          descriptions, suggestion, enhancement request, recommendation,
          correction or feedback in the form of message, text, images, graphics,
          photos, audio, video and any other content provided by you.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          15. CloudMind's Use of Non-identifiable information.
        </h2>
        <p className={`${pStyle}`}>
          Any non-identifiable information gathered by CloudMind through your
          use of the Services, included Anonymized Data, may be used by
          CloudMind for its own marketing, promotional and product development
          and optimization purposes, machine learning and artificial
          intelligence models, algorithms, and weighting development, and more
          specifically may be stored in a database and used by CloudMind to
          identify, customize and personalize user access and user experience
          within the CloudMind Platform. Such information may be shared with
          CloudMind's affiliates, suppliers, licensors, partners and clients in
          furtherance of the forgoing purposes. For greater certainty, any such
          information shared with a third-party as contemplated under these
          Terms shall not identify you, your caregiver or your care provider in
          any way whatsoever and shall be completely de-identified information.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          16. Third Party Services and Content.
        </h2>
        <p className={`${pStyle}`}>
          The Services may allow you to access and use services provided by
          third parties (“<strong>Third Party Services</strong>”). You are
          responsible for all fees and taxes that may be charged for the use of
          Third Party Services. You use any Third Party Services at your own
          risk. CloudMind makes no representations or warranties with respect
          to, nor does it guarantee or endorse, any Third Party Services.
          CloudMind does not guarantee the continued availability of Third Party
          Services, and CloudMind may disable a Third Party Service in
          CloudMind's sole discretion. Your dealings with the provider of any
          Third Party Services are solely between you and the provider.
          Accordingly, CloudMind expressly disclaims responsibility and
          liability for all Third Party Services, and you agree that CloudMind
          shall not be responsible for any loss or damage of any sort incurred
          because of any such dealings or because of your use of Third Party
          Services. If you have any issues with a Third Party Service, you must
          contact the provider of the Third Party Service directly.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          17. Links to Other Sites.
        </h2>
        <p className={`${pStyle}`}>
          The CloudMind Platform and the Content may provide links to other
          sites on the Internet for your convenience in locating or accessing
          related information, products, and services. These sites have not
          necessarily been reviewed by CloudMind and are maintained by third
          parties over which CloudMind exercises no control. Accordingly,
          CloudMind expressly disclaims any responsibility for the content, the
          materials, the accuracy of the information, and/or the quality of the
          products or services provided by, available through, or advertised on
          these third-party websites. Moreover, these links do not imply an
          endorsement with respect to any third party or any website or the
          products or services provided by any third party.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          18. Content, Functionality and Access.
        </h2>
        <p className={`${pStyle}`}>
          CloudMind may at any time, with or without notice, without liability,
          and for any reason (a) remove any Content from the CloudMind Platform,
          (b) remove any functionality from the Services, (c) change any
          functionality on the Services, (d) modify the CloudMind Platform, and
          (e) deny, suspend or terminate any person's access to the Services.
          CloudMind furthermore reserves the right to take any action related to
          the Services or the Content that is required to comply with applicable
          law.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          19. Disclaimer of Warranties.
        </h2>
        <p className={`${pStyle}`}>
          THE CLOUDMIND PLATFORM, HARDWARE, THE CONTENT AND THE SERVICE
          GENERALLY ARE PROVIDED “AS IS” AND “AS AVAILABLE”, AND MAY INCLUDE
          ERRORS, OMISSIONS, OR OTHER INACCURACIES. YOUR USE OF THE SERVICES,
          THE HARDWARE AND THE CONTENT IS AT YOUR OWN RISK. CLOUDMIND DISCLAIMS
          ALL WARRANTIES, REPRESENTATIONS, COVENANTS AND CONDITIONS (EXPRESS,
          IMPLIED OR STATUTORY) IN CONNECTION WITH THE SERVICES AND THE CONTENT,
          INCLUDING ANY WARRANTIES, REPRESENTATIONS, COVENANTS, CONDITIONS, OR
          OTHER TERMS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          ACCURACY, COMPLETENESS, PERFORMANCE, AND NON-INFRINGEMENT. CLOUDMIND
          MAKES NO REPRESENTATION OR WARRANTY OR ANY OTHER TERM THAT THE
          SERVICES, IN WHOLE OR IN PART, AND THE CONTENT WILL OPERATE ERROR FREE
          OR IN AN UNINTERRUPTED FASHION, OR THAT THE SERVICES AND THE CONTENT
          WILL BE SECURE OR FREE OF MALICIOUS CODE. CLOUDMIND MAKES NO
          REPRESENTATIONS OR WARRANTIES ABOUT THE HARDWARE OR ANY THIRD PARTY
          WEBSITES OR RELATED CONTENT DIRECTLY OR INDIRECTLY ACCESSED THROUGH
          LINKS IN THE CLOUDMIND PLATFORM, OR THE CONTENT. YOUR SOLE AND
          EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE SERVICES AND THE CONTENT
          IS TO STOP USING THEM.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          20. Limitation of Liability.
        </h2>
        <p className={`${pStyle}`}>
          IN NO EVENT WILL THE TOTAL AGGREGATE LIABILITY OF CLOUDMIND (AND
          CLOUDMIND'S DIRECTORS, OFFICERS, EMPLOYEES, PARTNERS, SUPPLIERS AND
          AGENTS, AND THE LONG TERM CARE PROVIDERS USING OR ACCESSING YOUR
          CONTENT, THIRD PARTY SERVICE PROVIDER, AND THE SUPPLIERS OF THE
          HARDWARE) FOR ALL CLAIMS, DAMAGES, LOSSES, LIABILITIES, COSTS AND
          EXPENSES (INCLUDING LEGAL FEES AND EXPENSES) (COLLECTIVELY “LOSSES”)
          TO YOU RELATED TO THE SERVICES, OR USE THEREOF, OR THE CONTENT, OR
          THESE TERMS, EXCEED THE LESSER OF (A) THE DIRECT DAMAGES SUFFERED BY
          YOU, AND (B) $100 CANADIAN DOLLARS.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          21. No Claim for Certain Damages.
        </h2>
        <p className={`${pStyle}`}>
          IN NO EVENT WILL CLOUDMIND (OR CLOUDMIND'S DIRECTORS, OFFICERS,
          EMPLOYEES, PARTNERS, SUPPLIERS OR AGENTS, OR YOUR LONG TERM CARE
          PROVIDERS USING OR ACCESSING YOUR CONTENT, THIRD PARTY SERVICE
          PROVIDERS, AND THE SUPPLIERS OF THE HARDWARE) BE LIABLE TO YOU FOR ANY
          INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY OR
          AGGRAVATED DAMAGES, OR FOR ANY LOSS OF REVENUE, SAVINGS, INCOME,
          BUSINESS, PROFIT, GOODWILL OR REPUTATION WHATSOEVER BASED ON ANY LEGAL
          THEORY (INCLUDING TORT OR NEGLIGENCE), AND EVEN IF ADVISED OF THE
          POSSIBILITY OF THOSE DAMAGES.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          22. Some Disclaimers, Exclusions or Limitations May Not Apply.
        </h2>
        <p className={`${pStyle}`}>
          In some circumstances, applicable law may not allow for limitations on
          certain implied warranties, or exclusions or limitations of certain
          damages. Solely to the extent that such law applies to you, some or
          all the above disclaimers, exclusions or limitations may not apply to
          you.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          23. Indemnity.
        </h2>
        <p className={`${pStyle}`}>
          You will indemnify and hold CloudMind (and CloudMind's directors,
          officers, employees, partners, suppliers and agents) harmless from all
          Losses arising from your use of the Services or the Content, or your
          breach of any of these Terms, and from all Losses resulting from any
          of Your Content that is untrue, inaccurate or incomplete.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          24. Failure to Comply.
        </h2>
        <p className={`${pStyle}`}>
          If you fail to comply with these Terms, then, without limiting any
          other right or remedy available to CloudMind, CloudMind may suspend or
          terminate your license to use all or any part of the Services or the
          Content.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          25. Export Controls.
        </h2>
        <p className={`${pStyle}`}>
          These Terms are expressly made subject to any laws, regulations,
          orders or other restrictions on export from the United States of
          America (U.S.) or Canada of the Services or the Content, or any
          information about any of them, which may be imposed from time to time
          by the governments of the U.S. or Canada. You shall not export the
          Services or the Content, or any information about any of them without
          the prior written consent of CloudMind and compliance with such laws,
          regulations, orders and other restrictions. You represent and warrant
          that (a) you are not located in a country that is subject to a U.S. or
          Canadian government embargo, or that has been designated by the U.S.
          or Canadian government as a “terrorist supporting” country, and (b)
          you are not listed on any U.S. or Canadian government list of
          prohibited or restricted parties.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          26. Amendments.
        </h2>
        <p className={`${pStyle}`}>
          The “last updated” legend above indicates when these Terms were last
          amended. CloudMind may unilaterally amend all or any part of these
          Terms at any time by updating these Terms on the CloudMind Platform.
          CloudMind will provide you with notice of the proposed amendments by
          posting an amended version of these Terms with a new version date or
          through communication with you by other means. CloudMind will include
          a link to the previous version of the Terms beneath the new version
          date. The amendments will take effect thirty (30) days after the date
          on which the amended version is posted. Prior to that date, the
          previous version of the Terms will continue to apply. If you disagree
          with any amendments, you may refuse the amendments and cease using the
          Services and the Content within the 30-day notice period. There will
          be no cost or penalty for doing so. If you continue to access or use
          the Services or the Content after the 30-day period, you thereby agree
          to the amended Terms. You agree to review these Terms regularly to
          determine your rights and responsibilities.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          27. Governing Law & Jurisdiction.
        </h2>
        <p className={`${pStyle}`}>
          These Terms, and any dispute, controversy or claim arising under, out
          of, in connection with, or related to (a) the Services or the Content,
          or (b) these Terms, or their subject matter, negotiation, performance,
          renewal, termination, interpretation, or formation, shall be governed
          by and interpreted according to the laws of the Province of British
          Columbia and the federal laws of Canada applicable in British
          Columbia, without regard to any conflicts of law rules that might
          apply the laws of any other jurisdiction. You and CloudMind each
          attorn to the exclusive jurisdiction of the courts of British Columbia
          in respect of any such dispute, controversy or claim, except that,
          notwithstanding the foregoing, (a) you agree that CloudMind shall be
          entitled to seek and be awarded an injunction or other appropriate
          equitable relief from a court of competent jurisdiction anywhere in
          the world restraining any breach, threatened or actual, of your
          obligations under any provision of these Terms, and (b) you agree that
          CloudMind shall be entitled to seek and be awarded an order from a
          court of competent jurisdiction anywhere in the world for the purpose
          of recognizing and enforcing any interim or final judgement, order,
          injunction, award or other relief granted or provided by the courts of
          British Columbia, and you hereby waive any defence you might then have
          to the granting of such an order.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          28. Injunction.
        </h2>
        <p className={`${pStyle}`}>
          You acknowledge that any breach, threatened or actual, of these Terms
          will cause irreparable harm to CloudMind, such harm would not be
          quantifiable in monetary damages, and CloudMind would not have an
          adequate remedy at law. You agree that CloudMind shall be entitled, in
          addition to other available remedies, to seek and be awarded an
          injunction or other appropriate equitable relief from a court of
          competent jurisdiction anywhere in the world restraining any breach,
          threatened or actual, of your obligations under any provision of these
          Terms, and without the necessity of showing or proving any actual or
          threatened damage or harm, notwithstanding any rule of law or equity
          to the contrary. You hereby waive any requirement that CloudMind post
          any bond or other security in the event any injunctive or equitable
          relief is sought by or awarded to CloudMind to enforce any provision
          of these Terms.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          29. Class Action Waiver.
        </h2>
        <p className={`${pStyle}`}>
          Any proceedings to resolve or litigate any dispute, controversy or
          claim arising under, out of, in connection with, or related to (a) the
          Services or the Content, or (b) these Terms, or their subject matter,
          negotiation, performance, renewal, termination, interpretation, or
          formation, will be conducted solely on an individual basis. Neither
          you nor CloudMind will seek to have any such dispute heard as a class
          action, private attorney general action, or in any other proceeding in
          which either party acts or proposes to act in a representative
          capacity. No proceeding will be combined with another without the
          prior written consent of all parties to all affected proceedings. If
          this class action waiver is found to be illegal or unenforceable as to
          all or some parts of a dispute, then this section will not apply to
          those parts.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          30. General.
        </h2>
        <p className={`${pStyle}`}>
          If any provision of these Terms is unlawful, void, or unenforceable,
          then that provision shall be deemed severed from the remaining
          provisions and shall not affect the validity and enforceability of the
          remaining provisions. All rights and remedies of CloudMind granted or
          recognized in these Terms are cumulative, are in addition to and not
          in substitution for any rights or remedies at law, and may be
          exercised at any time and from time to time independently or in any
          combination. In these Terms (a) references to currency are to the
          lawful money of Canada, (b) “person” includes individuals,
          corporations, partnerships, joint ventures, associations, trusts,
          unincorporated organizations, societies and all other organizations
          and entities recognized by law, and (c) “including” (and similar
          variations) means including without limitation. These Terms, together
          with any additional service terms presented on the CloudMind Platform
          or the Hardware (“Additional Service Terms”) represent the entire
          agreement between you and CloudMind with respect to use of the
          Services and Content, and they supersede all prior or contemporaneous
          terms, agreements, communications and proposals, whether electronic,
          oral, or written between you and CloudMind with respect to any of the
          foregoing. Failure by CloudMind to insist on strict performance of any
          of the terms or conditions of these Terms or any Additional Service
          Terms will not operate as a waiver by CloudMind of that or any
          subsequent default or failure of performance. CloudMind's affiliates,
          CloudMind's directors, officers, employees, partners, suppliers, and
          agents are third party beneficiaries of the sections titled
          “Disclaimer of Warranties”, “Limitation of Liability”, “No Claim for
          Certain Damages” and “Indemnity”. Apple, Google, and their
          subsidiaries are third party beneficiaries of these Terms. There are
          no other third-party beneficiaries of these Terms. You may not assign
          these Terms without the prior written consent of CloudMind. CloudMind
          may assign these Terms without restriction. These Terms will enure to
          the benefit of and will be binding on you and CloudMind and your and
          its respective successors and permitted assigns.
        </p>
      </div>
    </div>
  );
};

export default Terms;
