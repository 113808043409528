import { HashLink } from "react-router-hash-link";
import MenuLogo from "./../menu-logo.webp";
import Mailto from "./Mailto";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const NavBar = () => {
  // const scrollWithOffset = (el) => {
  //   const yCoordinate = el.getBoundingClientRect().top + window.pageY;
  //   const yOffset = -64;
  //   window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  // };

  const [showMenu, setShowMenu] = useState(false);
  const [navBar, setNavBar] = useState(true);

  let theMenuIcon;

  if (!showMenu) {
    theMenuIcon = faBars;
  } else {
    theMenuIcon = faClose;
  }

  const updateNavBar = () => {
    if (window.scrollY <= 64) {
      setNavBar(true);
    } else {
      setNavBar(false);
    }
  };

  window.addEventListener("scroll", updateNavBar);

  return (
    <nav className="navbar fade-in fixed top-0 z-50 h-16 w-full bg-white lg:bg-transparent">
      <div
        className={`absolute inset-0 -z-10 h-16 w-full ${
          navBar
            ? "transition-colors duration-300 lg:bg-transparent"
            : "shadow-md transition-colors duration-300 lg:bg-white"
        }`}
      ></div>

      <div className="container mx-auto flex h-full w-full items-center justify-between px-8">
        <div className="menu-logo initialize-nav-items relative z-50 flex transform justify-self-start">
          <HashLink smooth to="/#top" aria-label="Home - At top of page">
            <div className="relative flex h-auto w-full items-center justify-center">
              <img
                className="h-8 w-auto lg:h-12"
                src={MenuLogo}
                alt="Cloudmind.me Logo"
              />
            </div>
          </HashLink>
        </div>
        <div className="fade-in flex items-center justify-end">
          <div
            onClick={() => setShowMenu(false)}
            className={`${
              showMenu
                ? "absolute inset-0 z-10 h-[100vh] w-[100vw] bg-brand-dark opacity-70 transition-opacity lg:hidden"
                : "-z-0 opacity-0"
            }`}
          ></div>
          <div
            className={`"menu-items z-20 flex -translate-x-full transform flex-col items-center gap-6 p-8 pt-24 font-sans text-2xl tracking-[0.2em] text-brand-dark opacity-0 shadow-2xl transition-transform duration-500 lg:translate-x-0 lg:flex-row lg:gap-4 lg:p-0 lg:text-xl lg:text-brand-dark lg:opacity-100 lg:shadow-none lg:transition-none ${
              showMenu
                ? "menu-bg-block absolute inset-0 flex h-[100vh] w-[80%] translate-x-0 bg-gradient-to-b from-brand-light to-brand-dark opacity-100 sm:w-2/3 md:w-1/2 lg:relative lg:inset-auto lg:bg-none"
                : "opacity-0 lg:flex"
            }`}
          >
            {/* <HashLink
              smooth
              to="/#news"
              className="transition-colors duration-300 hover:text-brand-alt"
              scroll={(el) => scrollWithOffset(el)}
            >
              News
            </HashLink> */}
            <Mailto
              label="contact"
              mailto="mailto:contact@cloudmind.me?Subject=Cloudmind.me Website Contact"
            />
          </div>
          <FontAwesomeIcon
            icon={theMenuIcon}
            onClick={() => setShowMenu(!showMenu)}
            className="z-50 flex text-2xl text-brand-dark lg:hidden"
          />
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
