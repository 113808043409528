import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCqqA2XmRvnvEBApJZWOwA4DjvitWuQz5A",
  authDomain: "cloudmind-5b0f5.firebaseapp.com",
  projectId: "cloudmind-5b0f5",
  storageBucket: "cloudmind-5b0f5.appspot.com",
  messagingSenderId: "226218072857",
  appId: "1:226218072857:web:710914cb909d43358ac388",
  measurementId: "G-BETFRF2WK1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
console.log(analytics);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);
