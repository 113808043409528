import { useEffect, useLayoutEffect } from "react";

const Privacy = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "Cloudmind | Previous Privacy Statement";
  });

  const pStyle = "text-base text-brand-dark lg:text-lg mb-4 lg:mb-8 w-full";

  return (
    <div id="#top" className="terms h-auto py-24 lg:py-48">
      <div className="font-open container mx-auto px-8 text-brand-dark lg:px-40 2xl:px-56">
        <h1 className="mb-24 mt-12 font-sans text-2xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-3xl">
          Privacy Policy
        </h1>

        <p className={`${pStyle}`}>Last Updated: June 17, 2023</p>

        <p className={`${pStyle}`}>
          At Cloudmind, we are committed to protecting the privacy and security
          of our users' personal information. This Privacy Policy outlines the
          types of information we collect, how it is used, and the measures we
          take to protect it. By using our website, you consent to the
          collection, use, and disclosure of your information as described in
          this Privacy Policy.
        </p>

        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-2xl">
          Information Collection and Use
        </h2>

        <p className={`${pStyle}`}>
          The types of information we collect from our users include:
        </p>

        <p className={`${pStyle}`}>
          Personal Information: We may collect personal information such as your
          name, email address, and other contact details when you register an
          account with us or make a purchase through our website.
        </p>

        <p className={`${pStyle}`}>
          Payment Information: When you make a purchase through our website, we
          may collect payment information, such as your credit card number and
          billing address.
        </p>

        <p className={`${pStyle}`}>
          Log Data: When you use our website, we automatically collect
          information about your interactions with our website, such as IP
          address, browser type, operating system, and the pages you visit.
        </p>

        <p className={`${pStyle}`}>
          Cookies: We use cookies to track user activity and preferences on our
          website. You can control the use of cookies through your browser
          settings.
        </p>

        <p className={`${pStyle}`}>
          We use the information we collect from you to provide and improve our
          services, process transactions, and to communicate with you. We do not
          sell, rent, or share your personal information with third parties
          except as described in this Privacy Policy.
        </p>

        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-2xl">
          Data Security
        </h2>

        <p className={`${pStyle}`}>
          We take appropriate security measures to protect against unauthorized
          access, alteration, disclosure, or destruction of personal
          information. We use secure servers and encrypted connections to
          protect your information, and regularly review our security measures
          to ensure that they are up to date and effective.
        </p>

        <p className={`${pStyle}`}>
          However, no method of transmitting or storing information is
          completely secure, and we cannot guarantee the absolute security of
          your personal information. If you have reason to believe that your
          information is no longer secure, please immediately notify us.
        </p>

        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-2xl">
          Disclosure of Information
        </h2>

        <p className={`${pStyle}`}>
          We may disclose your information in the following circumstances:
        </p>

        <p className={`${pStyle}`}>
          To comply with a legal obligation or to respond to a request from law
          enforcement or other government authorities.
        </p>

        <p className={`${pStyle}`}>
          To protect the rights, property, or safety of Cloudmind, our users, or
          the general public.
        </p>

        <p className={`${pStyle}`}>
          To a third party in the event of a sale, merger, or transfer of all or
          a portion of our assets.
        </p>

        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-2xl">
          Changes to Privacy Policy
        </h2>

        <p className={`${pStyle}`}>
          We may modify this Privacy Policy from time to time. If we make
          changes to this Privacy Policy, we will notify you by revising the
          date at the top of this page and, in some cases, by providing
          additional notice (such as adding a statement to our homepage or
          sending you a notification). Your continued use of our website
          following the posting of changes to this Privacy Policy will
          constitute your acceptance of such changes.
        </p>

        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-2xl">
          Contact Us
        </h2>

        <p className={`${pStyle}`}>
          If you have any questions or concerns about this Privacy Policy or the
          information we collect and use, please contact us at [insert contact
          information].
        </p>
      </div>
    </div>
  );
};

export default Privacy;
