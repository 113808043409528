import React from "react"; // Import React only

const Mailto = ({ mailto, label }) => {
  const handleClick = (e) => {
    window.open(mailto, "_blank", "noreferrer"); // Open in new tab
    e.preventDefault(); // Prevent default link behavior
  };

  return (
    <a
      href={mailto}
      onClick={handleClick}
      className="transition-colors duration-300 hover:text-brand-main_light"
    >
      {label}
    </a>
  );
};

export default Mailto;
