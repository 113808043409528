import { Link } from "react-router-dom";
import { useEffect, useLayoutEffect } from "react";
import Mailto from "../components/Mailto";

const Privacy = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "Cloudmind | Privacy Statement";
  });

  const pStyle = "text-base text-brand-dark lg:text-lg mb-4 lg:mb-8 w-full";

  return (
    <div id="#top" className="terms h-auto py-24 lg:py-48">
      <div className="font-open container mx-auto px-8 text-brand-dark lg:px-40 2xl:px-56">
        <h1 className="mb-24 mt-12 font-sans text-2xl font-bold capitalize lg:mb-8 lg:mt-4 lg:text-3xl">
          CloudMind Privacy Statement
        </h1>
        <h3 class="mb-4 mt-2 font-sans text-lg font-bold capitalize lg:text-xl">
          Updated as of: June 20, 2024
        </h3>
        <p className={`${pStyle}`}>
          CloudMind Software Inc. and its affiliates (collectively, “we”, “our”
          or “us”) recognize the importance of privacy. The purpose of this
          privacy statement (“Privacy Statement”) is to inform you about our
          privacy practices, including how we collect, use and disclose your
          Personal Information.
        </p>
        <p className={`${pStyle}`}>
          This Privacy Statement applies to all of our operations, unless we
          have provided you with a separate privacy statement for a particular
          product, service or activity.
        </p>
        <p className={`${pStyle}`}>
          Please review this Privacy Statement carefully. By submitting your
          Personal Information to us, by registering for or using any of the
          services we offer, by using our website, or by voluntarily interacting
          with us, you consent to our collecting, using and disclosing your
          Personal Information as set out in this Privacy Statement, as revised
          from time to time.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          Privacy Statement Updates.
        </h2>
        <p className={`${pStyle}`}>
          This Privacy Statement is current as of the “updated” date, which
          appears at the top of this page. To review a summary of recent
          changes, click{" "}
          <Link
            className="mb-12 text-brand-alt underline transition-colors duration-300 hover:text-brand-alt_dark"
            to="/previous-privacy"
          >
            here
          </Link>
          . We may make changes to this Privacy Statement from time to time,
          which will become immediately effective when published in a revised
          Privacy Statement posted on our website unless otherwise noted. We may
          also communicate the changes through our services or by other means.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          What's in this Privacy Statement?
        </h2>
        <p className="mb-4 w-full text-base text-brand-dark lg:text-lg">
          This Privacy Statement covers the following topics:
        </p>
        <ul class="mt-2 list-disc pl-4 lg:pl-6">
          <li>Meaning of Personal Information</li>
          <li>Your Consent to Collection, Use and Disclosure</li>
          <li>Personal Information We Collect</li>
          <li>How We Use Your Personal Information</li>
          <li>How We Share Your Personal Information</li>
          <li>Opting Out of Communications</li>
          <li>Retention of Personal Information</li>
          <li>Information Security</li>
          <li>Accessing and Updating Your Personal Information</li>
          <li>Third Party Websites and Services</li>
          <li>Children's Personal Information</li>
          <li>How to Contact Us</li>
        </ul>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          Meaning of Personal Information.
        </h2>
        <p className={`${pStyle}`}>
          "Personal Information" means information about an identifiable
          individual as described under applicable privacy laws, which may
          include, but is not limited to, your name, e-mail address, telephone
          number, address, health card and personal health information
          (including your wellness data, medical history, name of care
          providers, and health care programs and services you receive from us
          or care providers that have invited you to register to use our
          products and services).
        </p>
        <p className={`${pStyle}`}>
          Personal Information does not include any business contact information
          that is solely used to communicate with you in relation to your
          employment, business or profession, such as your name, position name
          or title, work address, work telephone number, or work e-mail address.
        </p>
        <p className={`${pStyle}`}>
          Personal Information also does not include information that has been
          de-identified, anonymized or aggregated in such a way that there is no
          serious possibility it can be used to identify an individual, whether
          on its own or in combination with other information.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          Your Consent to Collection, Use and Disclosure.
        </h2>
        <p className={`${pStyle}`}>
          We collect, use and disclose your Personal Information with your
          consent or as permitted or required by applicable privacy laws. How we
          obtain your consent may be express or implied and will depend on the
          circumstances, as well as the sensitivity of the information
          collected. If you choose to provide Personal Information to us, we
          assume that you consent to the collection, use and disclosure of that
          Personal Information as outlined in this Privacy Statement.
        </p>
        <p className={`${pStyle}`}>
          If you provide Personal Information about another individual to us, it
          is your responsibility to obtain the consent of that individual to
          enable us to collect, use and disclose his or her information as
          described in this Privacy Statement.
        </p>
        <p className={`${pStyle}`}>
          If you wish to withdraw your consent to our collection, use or
          disclosure of your Personal Information, please contact us using the
          contact information in the “How to Contact Us” section below. In some
          cases, withdrawal of your consent may mean that we will no longer be
          able to provide certain products or services.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          Information We Collect.
        </h2>
        <h3 class="mb-4 mt-2 font-sans text-lg font-normal underline lg:text-xl">
          Products and Services.
        </h3>
        <p className="mb-4 w-full text-base text-brand-dark lg:text-lg">
          We may collect information from you or from your use of our products
          or services.
        </p>
        <ul class="mt-2 list-disc pl-4 lg:pl-6">
          <li>
            For care providers that use our dashboard, we collect Personal
            Information that you submit through your use of our products and
            services, such as your name, phone number, and care centre address.
          </li>
          <li>Your Consent to Collection, Use and Disclosure</li>
          <li>
            For individuals who use our platform to record their health and
            wellness, we collect Personal Information that you submit through
            your use of our products or services, such as:
          </li>
          <ul class="my-2 list-disc pl-6 lg:pl-10">
            <li>
              identity information, such as your name, gender, age, marital
              status, and ethnicity;
            </li>
            <li>
              health and wellness information, such as your health history,
              health questionnaire and assessment responses, health-related
              reports, and the names of your care providers;
            </li>
            <li>
              any other information you provide to use through your use of our
              products or services, such as your interests and hobbies;
            </li>
            <li>
              information you may provide to use via surveys and questionnaires;
            </li>
            <li>
              marketing and communications information, such as your preferences
              for receiving our marketing and promotional emails;
            </li>
          </ul>
          <li>
            We may automatically collect usage data that is identifiable with
            you when you use our products and services.
          </li>
          <li>
            We will aggregate and anonymize certain data collected in connection
            with your use of our products and services, which may be stored and
            used separately from the data and Personal Information referred to
            above. Such information is not considered Personal Information
            because it cannot be used to identify you.
          </li>
        </ul>
        <h3 class="mb-4 mt-2 font-sans text-lg font-normal underline lg:text-xl">
          Website.
        </h3>
        <p className={`${pStyle}`}>
          For individuals who visit our websites located at{" "}
          <Link
            className="mb-12 text-brand-alt underline transition-colors duration-300 hover:text-brand-alt_dark"
            to="/"
          >
            www.cloudmind.me
          </Link>{" "}
          , or any of our related websites (collectively, “
          <strong>our website</strong>”), we may collect information from you or
          from your activities on the site.
        </p>
        <ul class="mt-2 list-disc pl-4 lg:pl-6">
          <li>
            Like most websites and other Internet services, we may collect
            certain technical and device information about your use of our
            website, which may include your Internet protocol address,
            information about your device, browser and operating system, and the
            date and time of your visit, as well as the region or general
            location where your device is accessing the internet, and other
            information about the usage of our website, including a history of
            the pages you view.
          </li>
          <li>
            We may also use “cookies” or enlist third party services which use
            cookies to track your preferences and activities on our website.
            Cookies may store a variety of information, including the number of
            times that you access a site, your language preferences and the
            number of times that you view a particular page or other item on the
            site.
          </li>
          <li>
            If you enter information in the “Contact Us” section, we collect
            your name, email address, company you represent and details of your
            inquiry to enable us to respond to a general/business inquiry made
            by you on behalf of your company.
          </li>
        </ul>
        <h3 class="mb-4 mt-2 font-sans text-lg font-normal underline lg:text-xl">
          Other Interactions.
        </h3>
        <p className={`${pStyle}`}>
          For individuals who otherwise interact with us, whether in person, by
          phone or e-mail, through social media or otherwise, including
          individuals who might be interested in acquiring our products or
          services, who sign-up to receive newsletters or other communications,
          or who respond to surveys and questionnaires, we may collect
          information that you provide to us during these interactions. This
          information may include your name, e-mail address and other
          information you choose to provide us through these interactions.
        </p>
        <h3 class="mb-4 mt-2 font-sans text-lg font-normal underline lg:text-xl">
          Sensitive Information.
        </h3>
        <p className={`${pStyle}`}>
          We collect information about your health and well-being, such as
          health history, health questionnaire and assessment responses,
          health-related reports. These and other types of information could be
          treated as sensitive information or have special protections under the
          laws of your jurisdiction.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          How We Use Your Personal Information.
        </h2>
        <p className="mb-4 w-full text-base text-brand-dark lg:text-lg">
          We may use your Personal Information for purposes such as:
        </p>
        <ul class="mt-2 list-disc pl-4 lg:pl-6">
          <li>
            providing you with our products and services and supporting your use
            of our products and services;
          </li>
          <li>
            preparing reports or sharing your Personal Information with the care
            providers associated with your use of our products and services, or
            other care practitioners within your “circle of care”;
          </li>
          <li>contacting you relating to our products and services;</li>
          <li>
            monitoring the usage of our products and services to support their
            proper functioning and further improvement;
          </li>
          <li>
            analyzing the needs and activities of our customers and users to
            help us better serve them;
          </li>
          <li>
            generating de-identified data to conduct research and analysis
            related to our business, products and services;
          </li>
          <li>responding to inquiries and other requests;</li>
          <li>
            collecting opinions and comments about our products and services;
          </li>
          <li>developing marketing materials;</li>
          <li>
            providing you with information that we think may interest you,
            including information about our products and services (where we have
            consent to do so);
          </li>
          <li>investigating legal claims;</li>
          <li>
            for such other purposes as you may otherwise consent from time to
            time;
          </li>
          <li>as otherwise required or permitted by law.</li>
        </ul>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          How We Share Your Personal Information.
        </h2>
        <p className={`${pStyle}`}>
          We disclose your Personal Information to your care providers, your
          care facility and its staff. We may also provide your Personal
          Information to other care providers, such as pharmacists, physicians,
          nurses, naturopaths or lab technicians, to facilitate the provision of
          your healthcare. This helps to coordinate care and ensure that
          everyone involved in your healthcare has the right information to meet
          your healthcare needs.
        </p>
        <p className={`${pStyle}`}>
          In addition, we may transfer your Personal Information and other
          information to a third party in the event of any reorganization,
          merger, sale, joint venture, assignment, transfer or other disposition
          of all or any portion of our business, brands, affiliates,
          subsidiaries or other assets, or other business transaction.
        </p>
        <p className={`${pStyle}`}>
          We rely on third party services providers to perform a variety of
          services on our behalf, such as data storage and processing service
          providers, technical support providers, processing service providers,
          communication service providers, and research and analytics providers.
        </p>
        <p className={`${pStyle}`}>
          If we provide information to service providers, we require that the
          service providers keep your Personal Information secure, and only
          handle it for limited purposes. We do not authorize the service
          providers to disclose your Personal Information to unauthorized
          parties or to use your Personal Information for their direct marketing
          purposes.
        </p>
        <p className={`${pStyle}`}>
          Additionally, we may use and disclose your information when we believe
          such use or disclosure is permitted, necessary or appropriate: (a)
          under applicable law, including laws outside your country of
          residence; (b) to comply with legal process; (c) to respond to
          requests from public and government authorities, including public and
          government authorities outside your country of residence; (d) to
          enforce the terms of the agreements for our products and services; (e)
          to protect our rights, operations or property; and (f) to allow us to
          pursue available remedies or limit the damages that we may sustain.
        </p>
        <p className={`${pStyle}`}>
          If we otherwise intend to disclose your Personal Information to a
          third party, we will identify that third party and the purpose for the
          disclosure, and obtain your consent.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          Opting Out of Communications.
        </h2>
        <p className={`${pStyle}`}>
          If you no longer want to receive marketing-related emails from us, you
          may opt-out of receiving emails by clicking the “unsubscribe” link at
          the bottom of any email you receive from us. You may also opt-out by
          contacting us directly using the contact information in the “How to
          Contact Us” section below.
        </p>
        <p className={`${pStyle}`}>
          Please note that if you opt-out from receiving marketing-related
          emails, we may still need to send you communications about your use of
          our products or services, or other matters.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          Retention of Personal Information.
        </h2>
        <p className={`${pStyle}`}>
          We will use, disclose or retain your Personal Information only for as
          long as necessary to fulfill the purposes for which that Personal
          Information was collected and as permitted or required by applicable
          privacy laws.
        </p>
        <p className={`${pStyle}`}>
          If your Personal Information was collected in connection with your use
          of our products and services, upon request by you within thirty (30)
          days after the effective date of termination or expiration of the
          applicable term for such product or service, we will make any Personal
          Information related to those products or services for which such term
          has expired or been terminated, available to you. After such 30-day
          period, we will have no obligation to maintain, provide or make
          available such Personal Information, and we may thereafter delete or
          destroy all copies of the Personal Information in our systems or
          otherwise in its possession or control, unless legally prohibited from
          doing so.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          International Transfer and Storage of Information.
        </h2>
        <p className={`${pStyle}`}>
          Your Personal Information may be stored and processed in Canada and
          the US. Other jurisdictions may have different data protection rules
          than Canada. While your Personal Information is outside of Canada, it
          is subject to the laws of the country in which it is located. Those
          laws may require disclosure of your Personal Information to
          authorities in that country. For written information about our
          policies and practices regarding service providers outside of Canada,
          contact our Privacy Information Officer at the email address below.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          Information Security.
        </h2>
        <p className={`${pStyle}`}>
          We have implemented physical, organizational, contractual and
          technological security measures with a view to protecting your
          Personal Information and other information from loss or theft,
          unauthorized access, disclosure, copying, use or modification. We have
          taken steps to ensure that the only personnel who are granted access
          to your Personal Information are those with a business 'need-to-know'
          or whose duties reasonably require such information.
        </p>
        <p className={`${pStyle}`}>
          Despite the measure outlined above, no method of information
          transmission or information storage is 100% secure or error-free, so
          we unfortunately cannot guarantee absolute security. If you have
          reason to believe that your interaction with us is no longer secure
          (for example, if you feel that the security of any information that
          you provided to us has been compromised), please contact us
          immediately using the contact information in the “How to Contact Us”
          section below.
        </p>
        <p className={`${pStyle}`}>
          We may also require that you assist us in safeguarding your Personal
          Information. For example, if you activate an account through a link,
          you should use a unique and strong passwords and not share your
          password with others.
        </p>
        <p className={`${pStyle}`}>
          If you have reason to believe that your Personal Information is no
          longer secure, please contact us immediately using the contact
          information in the “How to Contact Us” section below.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          Rights regarding Personal Information
        </h2>
        <p className={`${pStyle}`}>
          We expect you, from time to time, to supply us with updates to your
          Personal Information, when required. We will not routinely update your
          Personal Information, unless such a process is necessary.
        </p>
        <p className={`${pStyle}`}>
          Individuals have a number of rights concerning their personal
          information under privacy laws. Under certain conditions, you may make
          a written request to review any Personal Information about you that we
          have collected, used or disclosed, and we will provide you with any
          such Personal Information to the extent required by applicable laws.
          You may also challenge the accuracy or completeness of your Personal
          Information in our records. If you successfully demonstrate that your
          Personal Information in our records is inaccurate or incomplete, we
          will amend the Personal Information as required.
        </p>
        <p className={`${pStyle}`}>
          We may require that you provide sufficient identification to fulfill
          your request to access or correct your Personal Information. Any such
          identifying information will be used only for this purpose. We will
          not charge you any fees to access your personal information in our
          records without first providing you with an estimate of the
          approximate fees, if any.
        </p>
        <p className={`${pStyle}`}>
          Under some privacy laws, you may be entitled to additional rights,
          including: (i) the right to withdraw consent to processing where
          consent is the basis of processing; (ii) the right to access your
          personal information and certain other supplementary information,
          under certain conditions; (iii) the right to object to unlawful data
          processing, under certain conditions; (iv) the right to erasure of
          personal information about you, under certain conditions; (v) the
          right to demand that we restrict processing of your personal
          information, under certain conditions, if you believe we have exceeded
          the legitimate basis for processing, processing is no longer
          necessary, are processing, or believe your personal information is
          inaccurate; (vi) the right to data portability of personal information
          concerning you that you provided us in a structured, commonly used,
          and machine-readable format, under certain conditions; (vii) the right
          object to decisions being taken by automated means which produce legal
          effects concerning you or similarly significantly affect you, under
          certain conditions; (viii) the right to lodge a complaint with data
          protection authorities.
        </p>
        <p className={`${pStyle}`}>
          If you reside in the United States, please see our Supplemental Notice
          for US residents.
        </p>
        <p className={`${pStyle}`}>
          Please note that such rights are not absolute. There are instances
          where applicable law or regulatory requirements allow or require us to
          refuse to provide some or all of the personal data that we hold about
          you. In the event that we cannot accommodate your request, we will
          inform you of the reasons why, subject to any legal or regulatory
          restrictions.
        </p>
        <p className={`${pStyle}`}>
          If you object to how we handle your request, you may have the right to
          make a complaint to the Information and Privacy Commissioner of
          Ontario in relation to health privacy matters or, if the complaint
          relates to other privacy matters, to the Privacy Commissioner of
          Canada.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          Third Party Websites and Services.
        </h2>
        <p className={`${pStyle}`}>
          We may provide links to third-party websites for your convenience and
          information. We may also make opportunities available to you to
          purchase, subscribe to, or use other products or services from third
          parties with different privacy practices. Those other websites,
          products, or services are governed by the privacy statements and
          policies of the respective third party. This Privacy Statement does
          not extend to any websites, products, or services provided by third
          parties, including the practices of your primary care physician who
          referred you to using our products and services. We do not assume
          responsibility for the privacy practices of these third parties and we
          encourage you to review all third-party privacy statements prior to
          using third-party websites, products, or services.
        </p>
        <h2 className="mb-4 mt-2 font-sans text-xl font-bold capitalize lg:mt-8 lg:text-2xl">
          How to Contact Us.
        </h2>
        <p className={`${pStyle}`}>
          All comments, questions, concerns or complaints regarding your
          Personal Information or our privacy practices should be sent to our
          Privacy Officer as follows:
        </p>
        <h3 class="mb-4 mt-2 font-sans text-lg font-bold capitalize lg:text-xl">
          Address:
        </h3>
        <p className={`${pStyle}`}>
          Attention: Privacy Officer<br></br>
          1800 - 510 West Georgia Street,<br></br>
          Vancouver, BC V6B 0M3<br></br>
          Vancouver, BC V6B 0M3<br></br>
          Canada
        </p>
        <h3 class="mb-4 mt-2 font-sans text-lg font-bold capitalize lg:text-xl">
          By e-mail:
        </h3>
        <div className="mb-12 text-brand-alt underline transition-colors duration-300 [&>a]:hover:text-brand-alt_dark">
          <Mailto
            label="privacy@cloudmind.me"
            mailto="mailto:privacy@cloudmind.me?Subject=Cloudmind.me Website Privacy Statement"
          />
        </div>
      </div>
    </div>
  );
};

export default Privacy;
