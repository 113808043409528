import HeroSection from "../components/HeroSection";
import PointSection from "../components/PointSection";
import InformationSection from "../components/InformationSection";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    document.title = "Cloudmind | Making connections one companion at a time.";
  });

  return (
    <div className="home">
      <HeroSection />
      <PointSection />
      <InformationSection />
    </div>
  );
};

export default Home;
