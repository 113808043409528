import { useInView } from "react-intersection-observer";
import { useFormFields, useMailChimpForm } from "use-mailchimp-form";

const Newsletter = () => {
  const { ref: scrollRef, inView: scrollInView } = useInView({
    triggerOnce: true,
    rootMargin: "-200px 0px",
  });

  const url = process.env.REACT_APP_MAILCHIMP_URL;
  const { loading, error, success, message, handleSubmit } =
    useMailChimpForm(url);
  const { fields, handleFieldChange } = useFormFields({
    EMAIL: "",
    FNAME: "",
    LNAME: "",
  });
  return (
    <div
      ref={scrollRef}
      data-inview={scrollInView}
      className={`container mx-auto mb-12 px-[5dvw] md:px-[15dvw] ${
        scrollInView ? "fade-in-up" : "opacity-0"
      }`}
    >
      <h2 className="font-sans text-2xl font-bold uppercase text-brand-alt_dark lg:text-3xl">
        Subscribe to our newsletter
      </h2>
      <p className="pb-8">
        Subscribe to our newsletter to receive the latest information on
        CLOUDMIND, BRiGHTPATH, and other news.
      </p>

      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleSubmit(fields);
        }}
      >
        <div className="grid w-full grid-cols-1 gap-4 pb-4 text-base md:grid-cols-2 lg:text-lg">
          <input
            id="FNAME"
            className="rounded-md border border-gray-300 bg-white p-2 placeholder:text-base"
            placeholder="First Name"
            type="text"
            value={fields.FNAME}
            onChange={handleFieldChange}
          />
          <input
            id="LNAME"
            className="rounded-md border border-gray-300 bg-white p-2 placeholder:text-base"
            placeholder="Last Name"
            type="text"
            value={fields.LNAME}
            onChange={handleFieldChange}
          />
          <input
            id="EMAIL"
            className="rounded-md border border-gray-300 bg-white p-2 placeholder:text-base"
            placeholder="Email"
            type="email"
            value={fields.EMAIL}
            onChange={handleFieldChange}
          />
          <button className="rounded-md border border-brand-main_light bg-brand-main p-2 text-brand-black transition-colors duration-300 hover:bg-brand-main_light">
            Subscribe to our newsletter
          </button>
        </div>
      </form>
      <div className="pt-4 text-sm">
        {loading && "submitting"}
        {error && message}
        {success && message}
      </div>
    </div>
  );
};

export default Newsletter;
