import { useEffect } from "react";
import { HashLink } from "react-router-hash-link";

const NotFound = () => {
  useEffect(() => {
    document.title = "Cloudmind | Making connections one companion at a time.";
  });

  const pStyle = "text-base text-black lg:text-lg mb-4 lg:mb-8 w-full";

  return (
    <div
      id="#top"
      className="terms to-brand-main_darkest h-auto bg-gradient-to-b from-brand-dark py-32 lg:py-56"
    >
      <div className="font-open container mx-auto flex max-w-6xl flex-col items-center justify-center px-8 text-center text-black lg:px-40 2xl:px-56">
        <h1 className="mb-24 mt-12 w-full font-sans text-2xl font-bold lg:mb-8 lg:mt-4 lg:text-3xl">
          Something is quite right. It looks like you tried to pull up a path
          that doesn't exist.
        </h1>

        <p className={`${pStyle}`}>
          Let's get you back{" "}
          <HashLink smooth to="/#top" className="underline">
            home.
          </HashLink>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
